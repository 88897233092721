import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const BenefitsSection = () => (
  <div className="bg-white w-full py-16 border-t border-b">
    <div className="w-11/12 max-w-screen-xl mx-auto">
      <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-16'>Why Fully Managed?</h2>

      <div className='grid grid-cols-1 xl:grid-cols-3 gap-8'>
        <div>
          <StaticImage
            src='../../images/services/chair-computer.jpg'
            width={800}
            layout="constrained"
            alt="Benefits of Fully Managed IT Services"
            placeholder='tracedSVG'
            formats={["auto", "webp", "avif"]}
            className='rounded shadow'
          />
          <h2 className='text-3xl text-site-lightblue font-bold mt-6 mb-6 border-b pb-3'>Peace of Mind</h2>
          <p className='text-lg text-gray-800'>Outsourcing the daily management activities of your IT environment means <b>your efforts can be focused on your core competencies and business goals</b>. As an IT Proactive client, you won’t have to worry about maintaining your IT hardware, managing an on-site data center, or monitoring for potential network/computer issues that could lead to downtime and profit loss; we will take care of all of that for you. In many cases, <b>we will inform you of a problem before you even see it</b>.</p>
        </div>

        <div>
          <StaticImage
            src='../../images/services/monthly-costs.jpg'
            width={800}
            layout="constrained"
            alt="Benefits of Fully Managed IT Services"
            placeholder='tracedSVG'
            formats={["auto", "webp", "avif"]}
            className='rounded shadow'
          />
          <h2 className='text-3xl text-site-lightblue font-bold mt-6 mb-6 border-b pb-3'>Predictable Monthly Costs</h2>
          <p className='text-lg text-gray-800'>Your accountant will tell you that operational expenses are better than capital expenses. <b>It’s better to spend a predictable flat-rate amount on a service as opposed to an outright purchase, hiring your own staff, or taking a gamble on the pay-by-the-hour repairman</b>. IT Proactive offers a comprehensive flat-rate monthly fee. This allows you to create a simple, easily predicted, yearly budget enabling you to sleep easy knowing you have coverage for all your IT issues.</p>
        </div>

        <div>
          <StaticImage
            src='../../images/services/professional-tech.jpg'
            width={800}
            layout="constrained"
            alt="Benefits of Fully Managed IT Services"
            placeholder='tracedSVG'
            formats={["auto", "webp", "avif"]}
            className='rounded shadow'
          />
          <h2 className='text-3xl text-site-lightblue font-bold mt-6 mb-6 border-b pb-3'>Professional Technicians</h2>
          <p className='text-lg text-gray-800'>Companies that handle their own IT in-house have to contend with hiring an expensive employee or dealing with someone who’s just good with computers. Even with an in-house employee you have to worry about training, covering vacation and sick time along with the potential of being overwhelmed by a flood of issues. <b>With IT Proactive, you get the benefit of an entire team of professionals who provide up-to date 24/7 support</b>.</p>
        </div>
      </div>
    </div>
  </div>
)

export default BenefitsSection