import React, { Fragment } from 'react'
import { Link } from "gatsby"
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const MenuDropdownCompany = () => {
  return (
    <div className="mr-6">
      <Menu as="div" className="relative text-left">
        <div>
          <Menu.Button className="inline-flex justify-center text-base text-gray-900 hover:text-red-600 tracking-wider">
            Company
            <ChevronDownIcon
              className="w-6 h-6 ml-2 -mr-1 text-gray-900"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-left bg-white border border-red-200 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none z-50 p-4">
            <div className="px-2 py-2">
              <Menu.Item>
                <Link
                  to="/team"
                  className='text-base text-gray-900 hover:text-red-600 tracking-wider'
                >
                  Meet Your Team
                </Link>
              </Menu.Item>
            </div>
            <div className="px-2 py-2">
              <Menu.Item>
                <a
                  href="https://itproactive.bamboohr.com/jobs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='text-base text-gray-900 hover:text-red-600 tracking-wider'
                >
                  Career
                </a>
              </Menu.Item>
            </div>
            <div className="px-2 py-2">
              <Menu.Item>
                <a
                  href="https://blog.itproactive.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='text-base text-gray-900 hover:text-red-600 tracking-wider'
                >
                  Tech Tips
                </a>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default MenuDropdownCompany