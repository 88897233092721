import React from 'react'
import { Link } from 'gatsby'

const QuickQuoteSection = () => (
  <div className="bg-blue-500 w-full py-16">
    <div className="w-11/12 max-w-2xl mx-auto">
      <h2 className='text-4xl text-white text-center font-bold mb-2 leading-tight'>To see your options, get a no-obligation Quick Quote today!</h2>

      <div className='flex justify-center'>
        <Link to="/quote">
          <button className="bg-transparent hover:bg-white text-white hover:text-gray-800 font-display border border-white px-8 py-4 text-xl text-center font-semibold tracking-wide rounded mt-8 shadow-lg">
            Get Quick Quote
          </button>
        </Link>
      </div>
    </div>
  </div>
)

export default QuickQuoteSection