import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import styled from 'styled-components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from "../components/layout/layout"
import SEOnew from "../components/seo-new"
import CapabilitiesSection from "../components/sections/capabilities-section"
import BenefitsSection from "../components/sections/benefits-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"

const StyledH1 = styled.h1`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const TeamPageTemplate = ({ data }) => {
  const doc = data.prismicTeamPage
  const imgSrc = getSrc(doc.data.hero_section_background_image)
  const imgHero = getImage(doc.data.hero_section_background_image)
  const imgDefault = getImage(data.imgDefault)
  const buttonLinkType = doc.data.hero_section_button_link.link_type
  const buttonLinkUid = doc.data.hero_section_button_link.uid
  const buttonLinkUrl = doc.data.hero_section_button_link.url
  const teamSectionList = doc.data.team_section_list

  return (
    <Layout>
      <SEOnew 
        title={doc.data.page_title ? doc.data.page_title : null} 
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        image={imgSrc ? imgSrc : null}
      />
      
      <div style={{ display: "grid" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            height: 450,
          }}
          width={1920}
          layout="constrained"
          alt={imgHero.alt ? imgHero.alt : "hero background image"}
          image={imgHero ? imgHero : imgDefault}
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className='w-full max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto'>
            <div className='w-11/12 flex flex-col items-center mx-auto'>
              <StyledH1 className='text-3xl md:text-5xl font-extrabold text-white uppercase text-center'>
                <span className='leading-tight'>{doc.data.hero_section_title ? doc.data.hero_section_title : "Hero Title"}</span>
              </StyledH1>
              <p className="text-xl text-white font-medium text-center mt-4">{doc.data.hero_section_description ? doc.data.hero_section_description : "Hero Description"}</p>

              <div className="flex flex-col md:flex-row items-center justify-between md:space-x-6">
                {buttonLinkType === "Document" || buttonLinkType === "Any"
                  ?
                  <Link to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}>
                    <button className="bg-red-600 hover:bg-red-700 text-white border border-red-600 font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
                      {doc.data.hero_section_button_name ? doc.data.hero_section_button_name : "Buton Name"}
                    </button>
                  </Link>
                  :
                  buttonLinkType === "Web" || buttonLinkType === "Media"
                  ?
                  <a
                    href={buttonLinkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="bg-red-600 hover:bg-red-700 text-white border border-red-600 font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
                      {doc.data.hero_section_button_name ? doc.data.hero_section_button_name : "Buton Name"}
                    </button>
                  </a>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white w-full border-b">
        <div className="w-11/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 mx-auto my-12">
          {teamSectionList.map(item => {
            const teamPhoto = getImage(item.photo)
            return (
              <div>
                <GatsbyImage
                  image={teamPhoto}
                  layout="constrained"
                  alt={teamPhoto.alt ? teamPhoto.alt : item.name}
                  formats={["auto", "webp", "avif"]}
                  className="rounded-md shadow-lg"
                />
                <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
                  {item.name ? item.name : "Name"}
                </h2>
                <p className="text-gray-800 italic mb-3">{item.position ? item.position : "Position"}</p>
                <hr />
                <p className="text-gray-900 mt-3">
                  {item.short_bio ? item.short_bio : "Short bio here..."}
                </p>
              </div>
            )
          })}
        </div>
      </div>

      <BenefitsSection />
      <QuickQuoteSection />
      <CapabilitiesSection />
    </Layout>
  )
}

export default withPrismicPreview(TeamPageTemplate)

export const TeamPageTemplateQuery = graphql`
  query($id: String!) {
    prismicTeamPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_title
        page_short_description
        hero_section_title
        hero_section_description
        hero_section_button_name
        hero_section_button_link {
          uid
          link_type
          url
        }
        hero_section_background_image {
          gatsbyImageData(
            width: 1920
          )
          alt
        }
        team_section_list {
          photo {
          gatsbyImageData(
            width: 580
          )
          alt
          }
          name
          position
          short_bio
        }
      }
    }
    imgDefault: file(relativePath: { eq: "header/header-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
        )
      }
    }
  }
`


