import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const CapabilitiesSection = () => (
  <div className="bg-white w-full py-24 px-4">
    <h2 className='text-2xl md:text-3xl text-blue-900 text-center font-bold mb-12'>Our Capabilities Include</h2>

    <div className='w-11/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-5 gap-8 mx-auto'>
      <div className='w-full flex items-center'>
        <StaticImage
          src="../../images/logo/microsoft.png"
        />
      </div>
      <div className='w-full flex items-center'>
        <StaticImage
          src="../../images/logo/sonicwall.png"
        />
      </div>
      <div className='w-full flex items-center'>
        <StaticImage
          src="../../images/logo/datto.png"
        />
      </div>
      <div className='w-full flex items-center'>
        <StaticImage
          src="../../images/logo/veeam.png"
        />
      </div>
      <div className='w-full flex items-center'>
        <StaticImage
          src="../../images/logo/vmware.png"
        />
      </div>
    </div>
  </div>
)

export default CapabilitiesSection