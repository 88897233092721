import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

const SEOnew = ({ title, description, image, article, noIndex }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query SEOnew {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
        }
      }
      defaultImage: file(relativePath: { eq: "header/header-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `)

  const {
    defaultTitle,
    titleTemplate,
    siteUrl,
  } = data.site.siteMetadata

  const defaultImage = getSrc(data.defaultImage)

  const SEO = {
    title: title || `${title || defaultTitle}`,
    description: description ? description : "",
    image: image || `${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={SEO.title} titleTemplate={titleTemplate}>
      <meta name="description" content={SEO.description} />
      <meta name="image" content={SEO.image} />

      {noIndex ? <meta name="robots" content="noindex" /> : null}

      {SEO.url && <meta property="og:url" content={SEO.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {SEO.title && <meta property="og:title" content={SEO.title} />}

      {SEO.description && (
        <meta property="og:description" content={SEO.description} />
      )}

      {SEO.image && <meta property="og:image" content={SEO.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {SEO.title && <meta name="twitter:title" content={SEO.title} />}

      {SEO.description && (
        <meta name="twitter:description" content={SEO.description} />
      )}

      {SEO.image && <meta name="twitter:image" content={SEO.image} />}      

      <script type="application/ld+json">
        {`
          {
            "@context" : "http://schema.org",
            "@type" : "LocalBusiness",
            "name" : "IT Proactive",
            "openingHours": "Mo,Tu,We,Th,Fr 08:00-18:00",
            "telephone" : "(949) 543-0234",
            "address" : {
              "@type" : "PostalAddress",
              "streetAddress" : "1314 W 5th Street, 2nd Floor",
              "addressLocality" : "Santa Ana",
              "addressRegion" : "CA",
              "postalCode" : "92703"
            },
            "priceRange" : "$$$"
          }
        `}
      </script>         
    </Helmet>
  )
}

export default SEOnew

SEOnew.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  noIndex: PropTypes.bool,
}

SEOnew.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  noIndex: false,
}