import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const HomeDavidSection = () => (
  <div className="bg-site-gray w-full pt-6">
    <div className="w-11/12 max-w-screen-lg flex flex-col lg:flex-row items-center justify-center mx-auto">
      <div className='w-full lg:w-1/2 p-4'>
        <h2 className='text-4xl md:text-5xl text-white font-bold mb-2'>David DeFrancis</h2>
        <p className='text-white mb-6 italic'>Owner/Founder</p>
        <p className='text-lg text-white'>David founded IT Proactive in 1998 with a focus on process, organization and efficiency. He has a degree in Business Administration and holds many IT certifications including Microsoft Certified System Engineer (MCSE). We are your trusted outsourced IT services company.</p>
        <Link to="/team">
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-display px-8 py-4 text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
            Meet Your Team
          </button>
        </Link>
      </div>

      <div className='w-full lg:w-1/2 mx-auto'>
        <StaticImage
          src="../../images/home-page/home-david-profile.png"
          width="600"
        />
      </div>
    </div>
  </div>
)

export default HomeDavidSection